<template>
  <div>
    <!-- Jumbotron start -->
    <div
      class="
        jumbotron
        team-member-page-jumbotron
        pt-5
        align-content-stretch align-items-end
        justify-content-end
        align-items-lg-start
        justify-content-lg-end
      "
      :style="{
        backgroundImage:
          'url(' + require('@/assets/team/' + profile.image) + ')',
      }"
    >
      <h1
        class="
          display-3
          w-100
          mx-auto
          text-white
          fw-bold
          text-lg-left
          px-4
          container-lg
        "
      >
        {{ profile.name }}
      </h1>
      <p class="h6 w-100 mx-auto px-4 text-white container-lg">
        {{ profile.role }} | {{ profile.credentials }}
      </p>

      <div
        class="
          position-relative
          container-lg
          mx-lg-auto
          d-flex
          align-content-center align-items-center
          justify-content-center
          align-items-lg-start
          justify-content-lg-start
        "
      >
        <svg
          class="d-block mt-5 mx-4 mx-lg-2"
          width="17px"
          height="82px"
          viewBox="0 0 17 82"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>Scroll Down Arrow</title>
          <g
            id="Homepage"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <g
              id="DOCS-Our-Team"
              transform="translate(-96.000000, -871.000000)"
              stroke="#65C8D0"
              stroke-width="2"
            >
              <g
                id="Group-5-Copy-2"
                transform="translate(104.000000, 912.000000) rotate(-270.000000) translate(-104.000000, -912.000000) translate(63.500000, 904.500000)"
              >
                <line
                  x1="73.4166667"
                  y1="0.416666667"
                  x2="80.2000398"
                  y2="7.2000398"
                  id="Line-3"
                ></line>
                <line
                  x1="0.5"
                  y1="7.2000398"
                  x2="80.2000398"
                  y2="7.2000398"
                  id="Line-3"
                ></line>
                <line
                  x1="73.4166667"
                  y1="7.25839304"
                  x2="80.2000398"
                  y2="14.0417662"
                  id="Line-3"
                  transform="translate(76.750000, 10.591726) scale(1, -1) translate(-76.750000, -10.591726) "
                ></line>
              </g>
            </g>
          </g>
        </svg>
      </div>

      <div style="d-none d-lg-flex height: 200px;"></div>

      <div
        class="
          position-relative
          row
          d-flex
          team-member-contact-information
          mt-5 mt
          w-100
        "
      >
        <div
          class="
            col col-xl-6
            d-none d-xl-flex
            align-content-center align-items-center
            justify-content-center
          "
        ></div>
        <div
          class="
            col col-12 col-lg-6 col-xl-3
            d-flex
            align-content-center align-items-center
            justify-content-center
          "
        >
          <svg
            class="mx-3"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style="fill: #ffffff; transform: ; msfilter: "
          >
            <path
              d="M17 2H7c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM7 16.999V5h10l.002 11.999H7z"
            ></path>
          </svg>
          <p class="text-white mb-0 h6">{{ profile.phone }}</p>
        </div>
        <div
          class="
            col col-12 col-lg-6 col-xl-3
            d-flex
            align-content-center align-items-center
            justify-content-center
          "
        >
          <svg
            class="mx-2"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style="fill: #ffffff; transform: ; msfilter: "
          >
            <path
              d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 4.7-8 5.334L4 8.7V6.297l8 5.333 8-5.333V8.7z"
            ></path>
          </svg>
          <p class="text-white mb-0 h6">{{ profile.email }}</p>
        </div>
      </div>
    </div>
    <!-- Jumbotron end -->

    <!-- Section - Two Column - Introduction Start -->
    <section class="bg-white px-4 px-lg-0 mt-0 mt-lg-4 bio-container">
      <ul
        class="
          nav nav-tabs nav-sections nav-member
          d-flex
          justify-content-lg-start
          mt-5
          mx-auto
          mt-lg-7
          mb-lg-5
          border-0
          container-lg
        "
        role="tablist"
      >
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="bio-tab"
            data-bs-toggle="tab"
            data-bs-target="#bio"
            type="button"
            role="tab"
            aria-controls="bio"
            aria-selected="true"
          >
            Biography
          </button>
        </li>
        <!-- <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="procedures-tab"
            data-bs-toggle="tab"
            data-bs-target="#procedures"
            type="button"
            role="tab"
            aria-controls="procedures"
            aria-selected="false"
          >
            Procedures
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="training-tab"
            data-bs-toggle="tab"
            data-bs-target="#training"
            type="button"
            role="tab"
            aria-controls="training"
            aria-selected="false"
          >
            Training
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="languages-tab"
            data-bs-toggle="tab"
            data-bs-target="#languages"
            type="button"
            role="tab"
            aria-controls="languages"
            aria-selected="false"
          >
            Languages
          </button>
        </li> -->
      </ul>

      <div class="tab-content tab-bio mx-auto border-0">
        <div
          class="tab-pane fade show active"
          id="bio"
          role="tabpanel"
          aria-labelledby="bio-tab"
        >
          <div class="container">
            <h2
              class="
                h2
                gf-montserrat
                fw-bold
                my-4
                px-lg-0
                pt-lg-0
                text-uppercase
              "
            >
              Biography
            </h2>
            <p v-html="profile.bio">
              <!-- {{ profile.bio }} -->
            </p>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="procedures"
          role="tabpanel"
          aria-labelledby="procedures-tab"
        >
          <div class="container">
            <h2
              class="
                h2
                gf-montserrat
                fw-bold
                my-4
                px-lg-0
                pt-lg-0
                text-uppercase
              "
            >
              Procedures
            </h2>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rerum
              illo reprehenderit neque quo, saepe, eligendi corrupti obcaecati
              sequi mollitia dolor id fugiat qui assumenda, eius cumque officiis
              tempore iure nisi! Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Cumque tempora suscipit quibusdam quisquam
              reprehenderit voluptates illo illum a unde sed exercitationem
              corporis, molestiae accusantium error reiciendis est sit
              praesentium placeat! Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Itaque rem ducimus, modi amet impedit dolore
              maxime dolorum error sit dolores praesentium at, dolor explicabo
              accusantium libero quaerat. Ipsum, maiores rerum.
            </p>
            <p class="mt-4">
              Quas, sunt? Lorem ipsum dolor sit amet, consectetur adipisicing
              elit. Numquam ut, sunt sapiente ab tempore vero quam repellat
              provident quidem inventore explicabo eaque necessitatibus possimus
              alias magni perspiciatis sequi, debitis eos. Lorem ipsum dolor,
              sit amet consectetur adipisicing elit. Magni quisquam asperiores
              consectetur ipsa deleniti omnis nisi totam error ut
              exercitationem, voluptatem saepe eum mollitia. Unde dignissimos
              sit expedita incidunt asperiores. Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Molestiae impedit, eveniet minus
              tempora rem minima veniam esse odio odit, commodi error, quam est
              tenetur doloremque magnam aperiam ad hic deleniti?
            </p>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="training"
          role="tabpanel"
          aria-labelledby="training-tab"
        >
          <div class="container">
            <h2
              class="
                h2
                gf-montserrat
                fw-bold
                my-4
                px-lg-0
                pt-lg-0
                text-uppercase
              "
            >
              Training
            </h2>
            <p>
              Cumque tempora suscipit quibusdam quisquam reprehenderit
              voluptates illo illum a unde sed exercitationem corporis,
              molestiae accusantium error reiciendis est sit praesentium
              placeat! Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Itaque rem ducimus, modi amet impedit dolore maxime dolorum error
              sit dolores praesentium at, dolor explicabo accusantium libero
              quaerat. Ipsum, maiores rerum. Lorem, ipsum dolor sit amet
              consectetur adipisicing elit. Quod cupiditate ducimus odit saepe
              sequi similique porro accusantium unde quasi voluptatem
              dignissimos minus natus necessitatibus, id blanditiis aspernatur
              optio ipsa provident!
            </p>
            <p class="mt-4">
              Unde dignissimos sit expedita incidunt asperiores. Lorem ipsum
              dolor sit amet consectetur adipisicing elit. Molestiae impedit,
              eveniet minus tempora rem minima veniam esse odio odit, commodi
              error, quam est tenetur doloremque magnam aperiam ad hic deleniti?
              Lorem ipsum dolor sit amet consectetur adipisicing elit. A
              cupiditate sapiente optio, expedita eum iusto esse vitae dicta
              perferendis explicabo natus reprehenderit eveniet non labore ab
              rem earum fugit officia!
            </p>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="languages"
          role="tabpanel"
          aria-labelledby="languages-tab"
        >
          <div class="container">
            <h2
              class="
                h2
                gf-montserrat
                fw-bold
                my-4
                px-lg-0
                pt-lg-0
                text-uppercase
              "
            >
              Languages
            </h2>
            <p>
              {{ profile.lang }}
            </p>
          </div>
        </div>
      </div>

      <div
        class="row container-md mx-lg-auto mt-lg-7 d-flex flex-column px-0 py-4"
      ></div>
    </section>
    <!-- Section - Two Column - Introduction End -->
    <template v-if="relatedProfiles.length > 0">
      <div class="section section-our-team">
        <div class="row container-xxl px-4 pt-lg-4 border-top">
          <h3
            class="
              h2
              fw-bold
              my-4 my-lg-5
              d-flex
              align-items-center
              justify-content-start
            "
          >
            {{ profile.role }}
            <span class="badge bg-primary rounded-pill mx-3">{{
              relatedProfiles.length
            }}</span>
          </h3>

          <div
            class="col-sm-6 col-lg-4 mb-3 mb-sm-4 pl-0"
            v-for="person in relatedProfiles"
            :key="person.name"
          >
            <div class="card card-profile border-0 p-0">
              <div
                class="card-img"
                :style="{
                  backgroundImage:
                    'url(' + require('@/assets/team/' + person.profile) + ')',
                }"
              ></div>

              <router-link :to="'/our-team/' + nameLink(person.name)"
                >View Profile</router-link
              >
              <div class="card-body h4 p-4 mb-0">
                <h3 class="card-title fw-bold">{{ person.name }}</h3>
                <p class="card-text">{{ person.credentials }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Profile",
  props: {
    profile: {
      type: Object,
    },
    relatedProfiles: {
      type: Array,
    },
  },
  methods: {
    nameLink(name) {
      return encodeURI(name);
    },
  },
};
</script>

<style></style>
